<template>
  <page-layout>
    <div class="wrapper user-admin-page">
      <div class="relative title-wrap">
        <h3 class="page-titles">
          Manage users - {{ currentOrganisation }}
        </h3>
        <p>
          Manage users in your organisation.
        </p>
        <div class="profile">
          <the-profile class="print:hidden" />
        </div>
      </div>

      <div class="flex">
        <button
          class="btn-secondary"
          @click="showAddUserModal = true"
        >
          Add user
        </button>
      </div>

      <table class="table w-full mt-12 border border-collapse border-slate-400">
        <thead>
          <tr>
            <th class="p-4 font-semibold text-left border border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200">
              <div class="flex space-x-4">
                <dropdown-table-header>
                  First Name
                </dropdown-table-header>
                <div />
              </div>
            </th>
            <th class="p-4 font-semibold text-left border border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200">
              <div class="flex space-x-4">
                <dropdown-table-header>
                  Last Name
                </dropdown-table-header>
                <div />
              </div>
            </th>
            <th class="hidden p-4 font-semibold text-left border border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200 md:table-cell">
              <div class="flex space-x-4">
                <dropdown-table-header>
                  Email
                </dropdown-table-header>
                <div />
              </div>
            </th>
            <th class="hidden p-4 font-semibold text-left border border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200 md:table-cell">
              <div class="flex space-x-4">
                <dropdown-table-header desc="Users role in the organisation, can be user or admin.">
                  User role
                </dropdown-table-header>
                <div />
              </div>
            </th>
            <th class="hidden p-4 font-semibold text-left border border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200 md:table-cell">
              <div class="flex space-x-4">
                <dropdown-table-header desc="Tendency in cognitive endurance.">
                  Actions
                </dropdown-table-header>
                <div />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="showLoading || empty">
            <td />
            <td>
              <div
                class="flex items-center justify-center w-full h-full"
              >
                <button
                  v-if="showLoading"
                  class="btn btn-lg btn-circle btn-ghost loading"
                />
                <span v-if="!showLoading && empty">
                  No users
                </span>
              </div>
            </td>
            <td />
            <td />
          </tr>
          <template v-else>
            <table-row
              v-for="user in users"
              :key="user.id"
              :user="user"
              @edit="editUser(user)"
              @delete="deleteUser(user)"
            />
          </template>
        </tbody>
      </table>
    </div>

    <add-user-modal
      @close="showAddUserModal = false; selectedUser = {}; fetchUsers()"
      v-show="showAddUserModal"
    />
    <edit-user-modal
      @close="showEditUserModal = false; selectedUser = {}; fetchUsers()"
      v-if="showEditUserModal"
      :user="selectedUser"
    />
    <delete-user-modal
      v-if="showDeleteUserModal"
      @close="showDeleteUserModal = false; selectedUser = {}; fetchUsers()"
      :user="selectedUser"
    />
  </page-layout>
</template>

<script>
import TableRow from '../components/TableRow.vue'
import DropdownTableHeader from '../components/DropdownTableHeader.vue'
import AddUserModal from '../components/AddUserModal.vue'
import EditUserModal from '../components/EditUserModal.vue'
import DeleteUserModal from '../components/DeleteUserModal.vue'
import PageLayout from '@/layouts/PageLayout.vue'
import TheProfile from '@/components/TheProfile.vue'

export default {
  components: {
    TableRow,
    DropdownTableHeader,
    AddUserModal,
    EditUserModal,
    DeleteUserModal,
    PageLayout,
    TheProfile
  },
  data () {
    return {
      users: [],
      showAddUserModal: false,
      showEditUserModal: false,
      showDeleteUserModal: false,
      showLoading: false,
      selectedUser: {}
    }
  },

  computed: {
    empty () {
      return this.users.length === 0
    },
    currentOrganisation () {
      return this.$store.state.USER.user.organisationName
    }
  },
  async mounted () {
    this.showLoading = true
    await this.fetchUsers()
    this.showLoading = false
  },

  methods: {
    async fetchUsers () {
      this.users = await this.$api.getOrganisationUsers()
    },
    editUser (user) {
      this.selectedUser = user
      this.showEditUserModal = true
    },
    deleteUser (user) {
      this.selectedUser = user
      this.showDeleteUserModal = true
    }
  }
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
