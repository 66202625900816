<template>
  <tr class="border-2">
    <td>
      <span
        class="text-base"
      >{{ user.firstName }}</span>
      <div
        v-if="showInvitedBadge"
        class="ml-2 badge badge-info"
      >
        Invited
      </div>
    </td>
    <td>
      <span
        class="text-base"
      >{{ user.lastName }}</span>
    </td>
    <td class="hidden md:table-cell">
      <span
        class="text-base"
      >{{ user.email }}</span>
    </td>
    <td class="hidden md:table-cell">
      <span
        class="text-base"
      >{{ user.role }}</span>
    </td>
    <td>
      <button
        class="cursor-pointer"
        @click="$emit('edit')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-8 h-8"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
          />
        </svg>
      </button>
      <button
        class="cursor-pointer"
        @click="$emit('delete')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-8 h-8 ml-6"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </td>
  </tr>
</template>

<script>

export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    showInvitedBadge () {
      return this.user.createdAt === this.user.updatedAt
    }
  }

}
</script>

<style>

</style>
