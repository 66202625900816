<template>
  <div
    class="fixed top-0 left-0 z-10 flex justify-center w-full h-full p-6 bg-black bg-opacity-50"
  >
    <div
      style=" min-height: 380px; min-width: 500px;"
      class="flex flex-col w-1/2 p-8 ml-8 bg-white rounded-lg h-2/4"
    >
      <h3 class="mb-4 text-lg font-semibold">
        Add a new user to the organisation
      </h3>
      <p>
        An invite will be sent to the provided email.
      </p>

      <div
        class="p-8 mt-8 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
        v-if="showEmailMsg"
      >
        Email already in use
      </div>

      <div
        class="p-8 mt-8 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
        v-if="showInvalidMsg"
      >
        Invalid Email
      </div>

      <div
        class="p-8 mt-8 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
        v-if="showOutOfUsersMsg"
      >
        Upgrade your subscription to add more users. You can upgrade your subscription <router-link
          :to="{name: 'Checkout'}"
          class="link"
        >
          here.
        </router-link>
      </div>

      <div class="w-full mt-8 form-control">
        <div class="mb-4">
          <input
            v-model="firstName"
            data-theme="mytheme"
            type="text"
            placeholder="First name"
            class="w-full px-4 py-4 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
            :class="{'input-error': requiredMsg[0] && submited}"
          >
        </div>

        <div class="mb-4">
          <input
            v-model="lastName"
            data-theme="mytheme"
            type="text"
            placeholder="Last name"
            class="w-full px-4 py-4 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
            :class="{'input-error': requiredMsg[1] && submited}"
          >
        </div>

        <div class="mb-4">
          <input
            v-model="email"
            data-theme="mytheme"
            type="text"
            placeholder="Email"
            class="w-full px-4 py-4 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
            :class="{'input-error': requiredMsg[2] && submited || showEmailMsg || showInvalidMsg}"
          >
        </div>
      </div>
      <div class="flex justify-end mt-auto">
        <div class="flex justify-end">
          <button
            @click="close"
            class="mr-8 btn-secondary"
          >
            Cancel
          </button>
          <button
            @click="submit"
            class="btn-primary"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      requiredMsg: [true, true, true],
      firstName: '',
      lastName: '',
      email: '',
      showInvalidMsg: false,
      showOutOfUsersMsg: false,
      showEmailMsg: false,
      submited: false
    }
  },

  methods: {
    close () {
      this.showInvalidMsg = false
      this.showEmailMsg = false
      this.showOutOfUsersMsg = false
      this.firstName = ''
      this.lastName = ''
      this.email = ''

      this.$emit('close')
    },
    async submit () {
      this.showInvalidMsg = false
      this.showEmailMsg = false
      this.showOutOfUsersMsg = false
      this.submited = true

      const userData = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email.toLowerCase()
      }
      // form validation checks if the user provided all the info
      Object.keys(userData).forEach((key, index) => {
        if (userData[key] !== '') {
          this.requiredMsg[index] = false
        }
      })
      if (this.requiredMsg.includes(true)) return

      try {
        const { data } = await this.$http.post('customers', userData)
        if (data.error === 'email') {
          this.showEmailMsg = true
          return
        }

        if (data.error === 'out_of_users') {
          this.showOutOfUsersMsg = true
          return
        }

        this.$emit('close')
      } catch (error) {
        if (error.response.data.statusCode === 400) {
          this.showInvalidMsg = true
        }
      }
    }
  }
}
</script>

<style>

</style>
