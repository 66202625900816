<template>
  <div
    class="fixed top-0 left-0 z-10 flex justify-center w-full h-full p-12 bg-black bg-opacity-50 "
  >
    <div
      style=" height: 200px; min-width: 350px;"
      class="flex flex-col items-center p-6 rounded-lg w-1/3 bg-white  3xl:w-1/4"
    >
      <h4>
        Delete user {{ user.firstName }}
      </h4>

      <div class="flex space-x-7 justify-evenly my-auto">
        <button
          @click="$emit('close')"
          class="btn-secondary"
        >
          Cancel
        </button>
        <button
          @click="submit"
          :disabled="submited"
          class="btn-primary"
        >
          Yes
        </button>

        <span
          v-if="error"
          class="text-red-600"
        >* Could not delete user. Try again later. </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      firstName: this.user.firstName,
      submited: false,
      error: false
    }
  },

  methods: {
    async submit () {
      try {
        await this.$http.delete(`customers/${this.user.id}`)
      } catch (error) {
        this.error = true
      }

      this.$emit('close')
    }
  }
}
</script>

<style>

</style>
