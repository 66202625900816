<template>
  <div
    class="fixed top-0 left-0 z-10 flex justify-center w-full h-full p-6 bg-black bg-opacity-50 "
  >
    <div
      style=" min-height: 435px; min-width: 350px;"
      class="flex flex-col items-center p-6 rounded-lg h-2/3 w-1/3 bg-white md:h-1/3 3xl:h-2/5 3xl:w-1/4"
    >
      <h3 class="text-lg font-semibold mb-4">
        Edit user {{ user.firstName }}
      </h3>
      <div>
        <div>
          <div class="form-control  w-full mt-8">
            <label class="label">
              <span class="font-medium">First name</span>
            </label>
            <input
              v-model="firstName"
              data-theme="mytheme"
              type="text"
              placeholder="First name"
              class="shadow appearance-none border rounded w-full py-4 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              :class="{'input-info': edited[0]}"
            >
          </div>

          <div class="form-control ">
            <label class="label">
              <span class="font-medium">Last name</span>
            </label>
            <input
              v-model="lastName"
              data-theme="mytheme"
              type="text"
              placeholder="Last name"
              class="shadow appearance-none border rounded w-full py-4 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              :class="{'input-info': edited[1] }"
            >
          </div>
          <div class="form-control">
            <label class="label">
              <span class="font-medium">Email</span>
            </label>
            <input
              v-model="email"
              data-theme="mytheme"
              type="text"
              placeholder="Email"
              class="shadow appearance-none border rounded w-full py-4 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              :class="{'input-info': edited[2] || showEmailMsg || showInvalidMsg}"
            >
          </div>
          <span
            v-if="showEmailMsg"
            class="text-red-600"
          >* Email already in use</span>
          <span
            v-if="showInvalidMsg"
            class="text-red-600"
          >* Invalid Email</span>
        </div>
      </div>
      <div class="flex mt-24 justify-evenly ">
        <button
          @click="$emit('close')"
          class=" w-1/2 mr-2 px-24 btn btn-secondary h-20"
        >
          Cancel
        </button>
        <button
          @click="submit"
          class=" w-1/2 mr-2 px-24 btn btn-primary h-20"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      email: this.user.email,
      showInvalidMsg: false,
      showEmailMsg: false,
      submited: false
    }
  },
  computed: {
    edited () {
      const userData = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email.toLowerCase()
      }

      // check what attribute is edited
      const editedArray = [false, false, false]
      Object.keys(userData).forEach((key, index) => {
        if (userData[key] !== this.user[key]) {
          editedArray[index] = true
        }
      })

      return editedArray
    }
  },

  methods: {
    async submit () {
      const userData = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email.toLowerCase()
      }

      try {
        await this.$http.put(`customers/${this.user.id}`, userData)
      } catch (error) {
        if (error.response.data.statusCode === 400) {
          this.showInvalidMsg = true
        }
      }

      this.$emit('close')
    }
  }
}
</script>

<style>

</style>
